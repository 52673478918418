/** @prettier */
// @flow

import { setSandboxMode } from '../helpers/sandbox';
import { setHideGdprConsent } from './hideGdprConsent';
import * as login from './login';
import { setPaystationWidgetUISettings } from './payStationWidgetSettings';
import { setShowCustomParamsUserId } from './showCustomParamsUserId';
import { setIsCustomPayStationTheme } from './showCustomPayStationTheme';
import { setShowHeaderLineMenu } from './showHeaderLineMenu';
import { setShowPriceInButton } from './showPriceInButton';
import * as subscribeBlock from './subscribeBlock';

export { init } from '#common/landingAPI';

export const setupBlocksAPI = (window: Object) => {
  window.SB = Object.assign({}, window.SB, {
    setSandboxMode,
    login,
    setShowCustomParamsUserId,
    setHideGdprConsent,
    setShowHeaderLineMenu,
    setShowPriceInButton,
    setIsCustomPayStationTheme,
    setPaystationWidgetUISettings,
    subscribeBlock,
  });
  window.SB._callbacks.forEach((cb) => cb(window.SB));
};
