var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "詳細を見る",
  "client.header.login.button": "ログイン",
  "client.header.logout.button": "ログアウト",
  "client.user-id-modal.input-placeholder": "ユーザーID",
  "client.user-id-modal.input-incorrect-id": "ユーザーIDが正しくありません。",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "ゲームからIDをコピーして貼り付けてみてください。",
  "client.user-id-modal.continue-button": "続ける",
  "client.view-nickname-modal.show-more.button": "詳細を見る",
  "client.privacy-settings.open-button": "プライバシー設定",
  "client.usa-privacy-settings.open-button": "わたしのデータを販売することは禁止です",
  /* default-data page texts */
  "document.pages.auth.title": "IDを入力してください",
  "document.pages.auth.placeholder": "例：123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "ユーザーIDが見つかりませんか？",
  "document.pages.auth.instruction.steps.description": "ユーザーが自分の個人IDを見つける方法を記述します。いくつかのステップやイメージを加えることができます。",
  "document.pages.seo.title": "新作ゲーム発表！",
  "document.pages.seo.description": "ゲームでプレイヤーを、敵やモンスター、ゴールドハンター、大いなる危険に満ちた大冒険に誘おう。",
  "document.pages.seo.ogTitle": "新作ゲーム発表！",
  "document.pages.seo.ogDescription": "ゲームでプレイヤーを、敵やモンスター、ゴールドハンター、大いなる危険に満ちた大冒険に誘おう。",
  "default-data.templates.google-play.contact-us": "連絡先：{email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "ゲーム内ウォレットにお金を補充する",
  "blocks.topup.values.description": "ユーザーIDを入力する",
  "blocks.topup.values.userIdPlaceholder": "例：123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "ユーザーIDが見つかりませんか？",
  "blocks.topup.values.userInstruction.stepTitleTag": "ステップの名",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "ユーザーが自分の個人IDを見つける方法を記述します。いくつかのステップやイメージを加えることができます。",
  "default-data.templates.topup.app-support": "アプリサポート",
  "default-data.templates.topup.age-raiting": "アプリレーティング",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>ゲームのタイトル</h1>",
  "blocks.retailers.values.title": "<h2>エディションを選択する</h2>",
  "blocks.hero.values.description": "ゲームでプレイヤーを、敵やモンスター、ゴールドハンター、大いなる危険に満ちた大冒険に誘おう。",
  "blocks.hero.values.affiliate": "こちらの購入の収益はアフィリエイトと共有されます",
  "blocks.hero.components.platforms.label": "対応サービス",
  "blocks.hero.components.subscribe.label": "メールアドレスを入力",
  "blocks.hero.components.subscribe.values.text" : "ニュースレターをサブスクライブして最新ニュースとアップデートを受信しよう",
  "blocks.hero.components.scroll.label": "スクロール",
  "blocks.footer.values.name": "テキストを入力",
  "blocks.footer.values.affiliate": "アフィリエイトプログラムに参加",
  "blocks.hero.components.buy.label": "今すぐ購入",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "利用不可能",
  "blocks.hero.components.customButton.link.label": "セットアップ ボタン",
  "blocks.header.components.customButton.link.label": "セットアップ ボタン",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "利用不可能",
  "blocks.packs.components.pack.customButton.link.label": "セットアップ ボタン",
  "blocks.packs.components.pack.standart.components.buy.label": "今すぐ購入",
  "blocks.packs.components.pack.premium.components.buy.label": "今すぐ購入",
  "blocks.packs.components.pack.ultimate.components.buy.label": "今すぐ購入",
  "blocks.packs.components.pack.platinum.components.buy.label": "今すぐ購入",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "利用不可能",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "利用不可能",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "利用不可能",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "利用不可能",
  "blocks.packs.components.pack.standart.value.ribbon": "人気",
  "blocks.packs.components.pack.premium.value.ribbon": "人気",
  "blocks.packs.components.pack.ultimate.value.ribbon": "人気",
  "blocks.packs.components.pack.platinum.value.ribbon": "人気",
  "blocks.description.values.title": "<h2>ゲームの説明</h2>",
  "blocks.description.components.text.label": "ゲームでプレイヤーを、敵やモンスター、ゴールドハンター、大いなる危険に満ちた大冒険に誘おう。人生を変えるドラマチックなイベントが展開。さあ、敵が潜む新たな世界で生き残りをかけて戦おう。現実とは別の世界で生き抜き、発見・探検・勝利を体験だ！",
  "blocks.faq.values.title": "<h2>よくある質問</h2>",
  "blocks.faq.components.question.question": "Your Gameのプレイ方法について",
  "blocks.faq.components.question.answer": "Your Gameをプレイする場合は、まずゲームを購入してメールアドレスを指定します。アクティベーションキーが届くので、そちらを入力してゲームをお楽しみください。",
  "blocks.news.values.title": "<h2>ニュース</h2>",
  "blocks.news.values.description": "最近の記事をチェックして、最新情報を確認してください。",
  "blocks.store.values.title": "<h2>ストア</h2>",
  "blocks.store.buy_button.text": "{amount}を今すぐ購入",
  "blocks.embed.values.title": "<h2>ソーシャルメディアウィジェット</h2>",
  "blocks.embed.components.twitter.title": "<h3>TWITTERフィード</h3>",
  "blocks.embed.components.facebook.title": "<h3>FACEBOOKフィード</h3>",
  "blocks.embed.components.twitch.title": "<h3>Twitch</h3>",
  "blocks.embed.components.discord.title": "<h3>Discord</h3>",
  "blocks.packs.values.title": "エディションを選択する",
  "blocks.packs.values.description": "各エディションには、購入後にもらえる限定特典が含まれています。",
  "blocks.packs.components.pack.standart.value.subtext": "今すぐ利用できる",
  "blocks.packs.components.pack.premium.value.subtext": "今すぐ利用できる",
  "blocks.packs.components.pack.ultimate.value.subtext": "今すぐ利用できる",
  "blocks.packs.components.pack.platinum.value.subtext": "今すぐ利用できる",
  "blocks.packs.components.pack.standart.value.title": "<h3>スタンダードエディション</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>プレミアムエディション</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>プラチナエディション</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>アルティメットエディション</h3>",
  "blocks.packs.components.pack.standart.value.description": "ゲームキー、専用バッジ、サウンドトラック、マップ、追加アーマーパック",
  "blocks.packs.components.pack.premium.value.description": "ゲームキー、専用バッジ、サウンドトラック、マップ、追加アーマーパック、追加武器パック",
  "blocks.packs.components.pack.platinum.value.description": "ゲームキー、専用バッジ、サウンドトラック、マップ、デジタルアートブック、追加アーマーパック、追加武器パック、スキンのランダムパック1つ",
  "blocks.packs.components.pack.ultimate.value.description": "ゲームキー、専用バッジ、サウンドトラック、マップ、デジタルアートブック、デジタルソングブック、追加アーマーパック、追加武器パック2つ、スキンのランダムパック3つ",
  "blocks.subscriptions.values.card.title": "プレミアムを登録する特典ゲット",
  "blocks.subscriptions.values.title": "サブスクリプションを有効にする",
  "blocks.subscriptions.values.heading": "サブスクリプション",
  "blocks.subscriptions.values.bullets.one.title": "デジタルコンテンツ",
  "blocks.subscriptions.values.bullets.one.description": "画像、壁紙、ビハインドシーンコンテンツ、ゲーム内サウンドトラック",
  "blocks.subscriptions.values.bullets.two.title": "ゲーム内臨時収入",
  "blocks.subscriptions.values.bullets.two.description": "キャラクタースキン、視覚効果、ストーリーカード",
  "blocks.subscriptions.values.bullets.three.title": "ゲーム内ルートボックス3個",
  "blocks.subscriptions.values.bullets.three.description": "Hall of Chaosのエクストラ5枚、キャラクター転生10回分",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "次回の更新",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "今すぐ更新",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "プランを管理する",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "プランを選択してください",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "サブスクリプションを構成する",
  "editor.subscriptions-packs.button.trial.days.for.free": "{trial}日間の無料体験",
  "editor.subscriptions-packs.button.then.price.per.day": "後は1日あたり{amount}",
  "editor.subscriptions-packs.button.then.price.per.week": "後は1週あたり{amount}",
  "editor.subscriptions-packs.button.then.price.per.month": "後は1月あたり{amount}",
  "editor.subscriptions-packs.button.then.price.per.year": "後は1年あたり{amount}",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "体験期間が終わると、{periodValue}日間ごとに{amount}",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "体験期間が終わると、{periodValue}週間ごとに{amount}",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "体験期間が終わると、{periodValue}月間ごとに{amount}",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "体験期間が終わると、{periodValue}年間ごとに{amount}",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "後は1ライフタイム あたり{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "初回購入価格であり、後は1日あたり{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "初回購入価格であり、後は1週あたり{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "初回購入価格であり、後は1月あたり{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "初回購入価格であり、後は1年あたり{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "初回限定価格であり、後は 1ライフタイムあたり{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "初回購入が終わると、{periodValue}日間ごとに{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "初回購入が終わると、{periodValue}週間ごとに{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "初回購入が終わると、{periodValue}月間ごとに{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "初回購入が終わると、{periodValue}年間ごとに{amount}",
  "editor.subscriptions-packs.button.price.per.day": "1日あたり{amount}",
  "editor.subscriptions-packs.button.price.per.week": "1週あたり{amount}",
  "editor.subscriptions-packs.button.price.per.month": "1月あたり{amount}",
  "editor.subscriptions-packs.button.price.per.year": "1年あたり{amount}",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{periodValue}日間ごとに{amount}",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{periodValue}週間ごとに{amount}",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{periodValue}月間ごとに{amount}",
  "editor.subscriptions-packs.button.price.per.year.multiple": "{periodValue}年間ごとに{amount}",
  "editor.subscriptions-packs.button.price.per.lifetime": "1ライフタイムあたり{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "{periodsCounts}期分以内は1日あたり<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}、後は1日あたり{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "{periodsCounts}期分以内は1週あたり<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}、後は1週あたり{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "{periodsCounts}期分以内は1月あたり<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}、後は1月あたり{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "{periodsCounts}期分以内は1年あたり<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}、後は1年あたり{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "{periodsCounts}期分以内は{periodValue}日間ごとに<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}で、終わると{periodValue}日間ごとに{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "{periodsCounts}期分以内は{periodValue}週間ごとに<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}で、終わると{periodValue}週間ごとに{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "{periodsCounts}期分以内は{periodValue}月間ごとに<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}で、終わると{periodValue}月間ごとに{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "{periodsCounts}期分以内は{periodValue}年間ごとに<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}で、終わると{periodValue}年間ごとに{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "初回購入価格は{amount}、後は1日あたり{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "初回購入価格は{amount}、後は1週あたり{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "初回購入価格は{amount}、後は1月あたり{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "初回購入価格は{amount}、後は1年あたり{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "初回購入価格は{amount}、終わると{periodValue}日間ごとに{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "初回購入価格は{amount}、終わると{periodValue}週間ごとに{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "初回購入価格は{amount}、終わると{periodValue}月間ごとに{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "初回購入価格は{amount}、終わると{periodValue}年間ごとに{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "初回購入価格は{amount}、後は1ライフタイムあたり{amountPromo}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "{periodsCounts}期分以内の価格であり、その後1日あたり{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "{periodsCounts}期分以内の価格であり、その後1週あたり{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "{periodsCounts}期分以内の価格であり、その後1月あたり{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "{periodsCounts}期分以内の価格であり、その後1年あたり{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "{periodsCounts}期分以内の価格であり、終わると{periodValue}日間ごとに{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "{periodsCounts}期分以内の価格であり、終わると{periodValue}週間ごとに{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "{periodsCounts}期分以内の価格であり、終わると{periodValue}月間ごとに{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "{periodsCounts}期分以内の価格であり、終わると{periodValue}年間ごとに{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "{periodsCounts}期分以内の価格であり、その後1ライフタイムあたり{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "その後は<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}、シーズン最後日は{periodEnds}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "その後は<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}、シーズン開始日は{periodStarts}",
  "editor.subscriptions-packs.button.then.price.season.ends": "その後は{amount}、シーズン最後日は{periodEnds}",
  "editor.subscriptions-packs.button.then.price.season.starts": "その後は{amount}、シーズン開始日は{periodStarts}",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "その後は1日あたり<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "その後は1週あたり<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "その後は1月あたり<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "その後は1年あたり<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "終わると{periodValue}日間ごとに<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "終わると{periodValue}週間ごとに<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "終わると{periodValue}月間ごとに<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "終わると{periodValue}年間ごとに<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "その後は1ライフタイム<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price": "その後は<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "1日あたり<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "1週あたり<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "1月あたり<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "1年あたり<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "{periodValue}日間ごとに<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "{periodValue}週間ごとに<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "{periodValue}月間ごとに<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "{periodValue}年間ごとに<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "1ライフタイムあたり<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price": "その後は{amount}",
  "editor.subscriptions-packs.button.season.ends": "シーズンの最終日は{periodEnds}",
  "editor.subscriptions-packs.button.season.starts": "シーズンの開始日は{periodStarts}",
  "editor.subscriptions-packs.button.price.period.day": "{amount} / 1日あたり",
  "editor.subscriptions-packs.button.price.period.week": "{amount} / 1週あたり",
  "editor.subscriptions-packs.button.price.period.month": "{amount} / 1月あたり",
  "editor.subscriptions-packs.button.price.period.year": "{amount} / 1年あたり",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount} / {periodValue}日間",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount} / {periodValue}週間",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount} / {periodValue}月間",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount} / {periodValue}年間",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount} / 1ライフタイム",
  "blocks.requirements.values.title": "<h2>動作環境</h2>",
  "blocks.requirements.components.platform_req.value.title": "プラットフォーム",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "グラフィック",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "OS",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "ストレージ",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "プロセッサー",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "サウンドカード",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "メモリー",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "最低",
  "default-data.values.requirements.title.recommended": "推奨",
  "default-data.values.requirements.item.directx.minimum": "バージョン9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2.5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GB RAM",
  "default-data.values.requirements.item.sound_card.minimum": "DirectX9対応",
  "default-data.values.requirements.item.sound_card.recommended": "DirectX9対応",
  "default-data.values.requirements.item.directx.recommended": "バージョン9.0c",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GB RAM",
  "default-data.customization.receipt.title": "こんにちは！",
  "default-data.customization.receipt.description": "ご購入まことにありがとうございます。私たちのゲームで遊んでくれてありがとう。",
  "default-data.customization.receipt.footer": "© All rights reserved.",
  "blocks.store.components.storeSection.title": "アイテム",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "サブスクリプションに成功しました！",
  "notification.hero.components.subscribe.subscription.success": "確認Eメールを送信しました。Eメールに記載されたリンクをクリックして、サブスクリプションを有効化してください。",
  "notification.hero.components.subscribe.error": "問題が発生しました。しばらくしてからもう一度お試しください。または当社の統合チーム<a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>までお問い合わせください。",
  "notification.hero.components.subscribe.email.taken.error": "既にサブスクリプションをお持ちです。",
  /* notifications values END */
  "period.years_short": "年",
  "period.months_short": "ヵ月",
  "period.weeks_short": "週",
  "period.days_short": "日",
  "subscription.button.text": "{periodCount}&thinsp;{periodName}あたり{fullCurrency}</br>でサブスクライブする",
  "client.subscription.button.current.text": "あなたのサブスクリプション",
  "client.subscription.button.configuration": "サブスクリプションを管理する",
  "client.subscription.button.lifetime_text": "{fullCurrency}で</br>終身サブスクリプションを利用する",
  "client.thank_you_modal.title": "サブスクリプションのご購入ありがとうございます！",
  "client.thank_you_modal.description": "サブスクリプションは自動で更新されます。サブスクリプションの期間に従って定期的に請求が発生します。自動更新のキャンセルはいつでもできます。",
  "client.thank_you_modal.input.description": "当社のサブスクライバーのリストでご自身を確認する場合には、以下に名前を入力してください。",
  "client.thank_you_modal.button.default.text": "了解。無視する",
  "client.thank_you_modal.input.placeholder": "名前",
  "client.thank_you_modal.button.show_list": "すべてのサブスクライバーを表示する",
  "client.subscribers_list_modal.title": "サブスクライバーの完全リスト",
  "client.subscribers_list_modal.description": "参加していただいたすべてのユーザーとゲームをサブスクライブしてくださったユーザーに感謝いたします。",
  "client.subscribers_list_modal.show_more_button": "詳細を見る",
  "client.subscribers_list_modal.anonymous": "そして匿名のユーザー",
  "client.footer.button.list_of_subscribers": "サブスクライバーのリスト",
  "client.footer.xsollalogo.powered": "運営者", // копирайт в футере
  "client.footer.xsollalogo.sb": "エクソラ サイトビルダー",
  "client.empty_subscribers_modal.title": "サブスクライバーはここにリストアップされます",
  "client.empty_subscribers_modal.description": "当社のゲームをサブスクライブする最初のユーザーになりましょう。",
  "client.empty_subscribers_modal.button": "今すぐサブスクリプションを選択",
  "client.news.blurred.description": "このコンテンツのロックを解除するには、{subscription_name}の購読者になります。",
  "client.news.blurred.button.join": "今すぐ登録",
  "client.cart_modal.title": "カート",
  "client.cart_modal.item.pre-order": "（先行予約）",
  "client.cart_modal.clear_all": "すべてクリアをする",
  "client.cart_modal.item_purchased_description": "このアイテムは既に購入しました",
  "client.cart_modal.subtotal_title": "合計",
  "client.cart_modal.subtotal_subtitle": "追加の税金と手数料が適用される場合があります",
  "client.cart_modal.continue_button": "ショッピングを続ける",
  "client.cart_modal.checkout_button": "チェックアウトに行く",
  "client.cart_modal.footer-auth": "<button class=\'xl_auth\' data-xl-route=\'login\'>ログイン</button>または<button class=\'xl_auth\' data-xl-route=\'signup\'>登録</button>して、後で使用するためにアイテムをカートに保存するか、以前に保存したアイテムを表示します",
  "client.cart_modal.empty_cart_description": "あなたのカートは空です。ストアに行って、素晴らしいものを見つけましょう。",
  "client.cart_modal.empty_cart_open_store_button": "ストアを開く",
  "client.cart_modal.email_label": "ゲームアクティベーションキーを次に送信します：",
  "client.cart_modal.email_description": "プレイを開始するためにアクティブ化する必要があるキーを受け取ります。",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "ボーナス",
  "client.cart_modal.promo_codes.apply": "適用",
  "client.cart_modal.promo_codes.remove": "削除",
  "client.cart_modal.promo_codes.placeholder": "プロモーションコード",
  "client.cart_modal.promo_codes.error_incorrect": "プロモーションコードが正しくありません。",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "コードの引き換え制限に達しました。",
  "client.cart_modal.promo_codes.error_expired": "このプロモーションコードは期限切れです。",
  "client.cart_modal.promo_codes.error_could_not_apply": "プロモーションコードを適用できませんでした。後でもう一度お試しください。",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "カートに入れたアイテムには、プロモコードは有効ではありません。",
  "client.cart_modal.promo_codes.error_select_game_platform": "続行するにはゲームプラットフォームを選択してください。",
  "client.cart_modal.promo_codes.select_drm": "プラットフォームを選択",
  "client.cart_modal.promo_codes.tooltip_drm": "プラットフォームを変更するには、プロモーションコードを削除してから、もう一度適用してください",
  "client.bundle_modal.group_items": "バンドルアイテム",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>ブロンズ</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>デジタルコンテンツ</h3><p>✓ 芸術</p><p>✓ 壁紙</p><p><span style=\'color:rgb(127, 96, 0);\'>× ゲーム内のサウンドトラック </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× 舞台裏のコンテンツ</span></p><p><br></p><h3>3 ゲーム内</h3><h3>戦利品ボックス</h3><p>✓ キャラクタースキン</p><p><span style=\'color:rgb(127, 96, 0);\'>× 視覚効果 </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× ストーリーラインカード</span></p><p><br></p><h3>ゲーム内特典</h3><p>✓ x1 混沌ホールへの追加パス</p><p>✓ x1 キャラクター再生</p><p><br></p><h3>ディスコード</h3><p>✓ ゲーマーのチャンネルへのアクセス</p><p><span style=\'color:rgb(127, 96, 0);\'>× 開発者のチャンネルへのアクセス</span></p><p><br></p><h3>フォーラム</h3><p>✓ 専属ニックネームの色</p><p><span style=\'color:rgb(127, 96, 0);\'>× 専属絵文字</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× アニメのプロフィール画像 </span></p><p>✓ x2 ポストをランク付けするための追加ポイント（毎日）</p><p><br></p><h3>マーチ</h3><p>✓ ピンとステッカー（6か月ごと）</p><p>✓ 高品質のポスター（6か月ごと）</p><p><span style=\'color:rgb(127, 96, 0);\'>× ブランドのぬいぐるみ（毎年）</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× 高品質の置物（毎年）</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>オフラインイベント</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× ゲームコンベンションへの無料アクセス</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× 会社事務所への訪問（毎年）</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ ディスコードへのアクセスを与える</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>シルバー</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>デジタルコンテンツ</h3><p>✓ 芸術</p><p>✓ 壁紙</p><p><span style=\"color:rgb(127, 96, 0);\">× ゲーム内のサウンドトラック</span></p><p>✓ 舞台裏のコンテンツ</p><p><br></p><h3>6 ゲーム内</h3><h3>戦利品ボックス</h3><p>✓ キャラクタースキン</p><p>✓ 視覚効果</p><p><span style=\"color:rgb(127, 96, 0);\">× ストーリーラインカード</span></p><p><br></p><h3>ゲーム内特典</h3><p>✓ x2 混沌ホールへの追加パス</p><p>✓ x2 キャラクター再生</p><h3><br></h3><h3>ディスコード</h3><p>✓ ゲーマーのチャンネルへのアクセス</p><p><span style=\"color:rgb(127, 96, 0);\">× 開発者のチャンネルへのアクセス</span></p><p><br></p><h3>フォーラム</h3><p>✓ 専属ニックネームの色</p><p><span style=\"color:rgb(127, 96, 0);\">× 専属絵文字</span></p><p><span style=\"color:rgb(127, 96, 0);\">× アニメのプロフィール画像</span></p><p>✓ 5x ポストをランク付けするための追加ポイント（毎日）</p><p><br></p><h3>マーチ</h3><p>✓ ピンとステッカー（6か月ごと）</p><p>✓ 高品質のポスター（6か月ごと）</p><p><span style=\"color:rgb(127, 96, 0);\">× ブランドのぬいぐるみ（毎年）</span></p><p><span style=\"color:rgb(127, 96, 0);\">× 高品質の置物（毎年）</span></p><p><br></p><h3>オフラインイベント</h3><p>✓ ゲームコンベンションへの無料アクセス</p><p><span style=\"color:rgb(127, 96, 0);\">× 会社事務所への訪問（毎年）</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ ディスコードへのアクセスを与える</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>ゴールド</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>デジタルコンテンツ</h3><p>✓ 芸術</p><p>✓ 壁紙</p><p>✓ ゲーム内のサウンドトラック</p><p>✓ 舞台裏のコンテンツ</p><p><br></p><h3>9 ゲーム内</h3><h3>戦利品ボックス</h3><p>✓ キャラクタースキン</p><p>✓ 視覚効果</p><p>✓ ストーリーラインカード</p><p><br></p><h3>ゲーム内特典</h3><p>✓ x5 混沌ホールへの追加パス</p><p>✓ x10 キャラクター再生</p><p><br></p><h3>ディスコード</h3><p>✓ ゲーマーのチャンネルへのアクセス</p><p>✓ 開発者のチャンネルへのアクセス</p><p><br></p><h3>フォーラム</h3><p>✓ 専属ニックネームの色</p><p>✓ 専属絵文字</p><p>✓ アニメのプロフィール画像</p><p>✓ x12 ポストをランク付けするための追加ポイント（毎日）</p><p><br></p><h3>マーチ</h3><p>✓ ピンとステッカー（6か月ごと）</p><p>✓ 高品質のポスター（6か月ごと）</p><p>✓ ブランドのぬいぐるみ（毎年）</p><p>✓ 高品質の置物（毎年）</p><p><br></p><h3>オフラインイベント</h3><p>✓ ゲームコンベンションへの無料アクセス</p><p>✓ 会社事務所への訪問（毎年）</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ ディスコードへのアクセスを与える</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>デジタルコンテンツ</h3><p>芸術</p><p>壁紙</p><p><span>ゲーム内のサウンドトラック</span></p><p><span>舞台裏のコンテンツ</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>ゲーム内</h3><h3>戦利品ボックス</h3><p>キャラクタースキン</p><p><span>視覚効果</span></p><p><span>ストーリーラインカード</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>ゲーム内特典</h3><p>x1 混沌ホールへの追加パス</p><p>x1 キャラクター再生</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>ディスコード</h3><p>ゲーマーのチャンネルへのアクセス</p><p><span>× 開発者のチャンネルへのアクセス</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>フォーラム</h3><p>専属ニックネームの色</p><p>× 専属絵文字<span></p><p><span>× アニメのプロフィール画像</span></p><p>x2 ポストをランク付けするための追加ポイント（毎日）</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>マーチ</h3><p>ピンとステッカー（6か月ごと）</p><p>高品質のポスター（6か月ごと）</p><p><span>ブランドのぬいぐるみ（毎年）</span></p><p><span>高品質の置物（毎年）</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>オフラインイベント</h3><p>✓ ゲームコンベンションへの無料アクセス</p><p>✓ 会社事務所への訪問（毎年）</p>",
  "client.buy_button.text": "今すぐ購入",
  "client.buy_button.unavailable": "利用不可能",
  "client.buy_button.pre-order": "プレオーダー",
  "client.buy_button.checkout": "チェックアウトに行く",
  "client.store.cart.add_button": "カートに入れる",
  "client.store.cart.header_button": "カート",
  "client.store.no_items": "現在、ストアは空です。 後で戻ってきます！",
  "client.store.error.gt10_gk": "詐欺から保護するために、一度に10つのキーしか購入できません。",
  "client.store.error.no_keys": "このゲームは一時的に品切れとなっており、近日中に再入荷予定です。更新情報はお気軽にお問い合わせください。",
  "client.store.item.purchased": "購入済み",
  "client.store.item.price_from": "最低{price}",
  "client.store.item.show_more": "詳細を表示",
  "client.store.successful_purchase": "成功。 ご購入いただきありがとうございます！",
  "client.store.not_enough_vc": "仮想通貨が足りません。</br>購入を完了するには、それを補給してください。",
  "client.store.non_consumable_item": "このアイテムは以前に購入したことがあり、利用できなくなりました。",
  "client.store.user_id_input_before_submit_hint": "アイテムはこのアカウントにクレジットされます。",
  "client.store.user_id_input_after_submit_hint": "以下のために購入しています：",
  "client.common_error": "問題が発生しました。しばらくしてからもう一度お試しください。または当社の統合チーム<a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>までお問い合わせください。",
  "client.user_account.balance_title": "あなたの残高",
  "client.header.logout": "ログアウト",
  "client.retailers.edition.title": "コピーを選択する",
  "client.retailers.country.title": "国を選択する",
  "client.retailers.platform.title": "プラットフォームを選択する",
  "client.retailers.retailer.title": "販売者を選択する",
  "client.retailers.edition.digital": "デジタル",
  "client.retailers.edition.physical": "フィジカル",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "開発者から購入する",
  "client.retailers.expand_button.collapse": "閉じる",
  "client.retailers.buy_button.buy": "今すぐ購入",
  "blocks.retailers.components.retailers.standart.value.ribbon": "人気",
  "blocks.retailers.components.retailers.standart.value.subtext": "今すぐ利用できる",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>スタンダードエディション</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "ゲームキー、専用バッジ、サウンドトラック、マップ、追加アーマーパック",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>ゲームのタイトル</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "ゲームでプレイヤーを、敵やモンスター、ゴールドハンター、大いなる危険に満ちた大冒険に誘おう。",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "対応サービス",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "メールアドレスを入力",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "ニュースレターをサブスクライブして最新ニュースとアップデートを受信しよう",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "セットアップ ボタン",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "今すぐ購入",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "セットアップ ボタン",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "セットアップ ボタン",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "セットアップ ボタン",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "利用不可能",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "セットアップ ボタン",
  "client.topup.input.errorMessage": "何か問題が発生しました。ユーザーIDが正しいことを確認するか、後でもう一度お試しください。",
  "client.topup.input.successMessage": "{userID}IDでログインしました。これでサイトでの購入ができます。",
  "client.topup.input.username": "ユーザー名：{name}",
  "client.topup.input.id": "ID：{id}",
  "client.topup.input.level": "レベル：{level}",
  "client.topup.input.user_not_found": "指定したIDのユーザーが見つかりませんでした。 <br/>もう一度やり直してください。",
  "client.topup.input.serverError": "問題が発生しました。<br>このメッセージを複数回受信する場合は、{contact_us}ください。",
  "client.topup.input.serverError.contact_us": "お問い合わせ",
  "client.topup.authorized_title": "以下のために購入しています：",
  "blocks.store.values.custom_title": "<h2>{gameTitle}ストア</h2>"
};

module.exports = localizations;
