import React, { useMemo, useEffect, useCallback, CSSProperties } from 'react';
import { injectIntl } from 'react-intl';
import { useQuill } from 'react-quilljs';

import type { IntlShape } from 'react-intl';

import '../../../../styles/quill-editor.css';
import { COLORS, FORMATS, importQuillModules } from './configurations';
import './style.pcss';

type Props = {
  onBlur: (value: string) => void;
  onFocus: () => void;
  initialText: string;
  intl: IntlShape;
  disableTextAlignSettings?: boolean;
};

const QuillEditor = ({
  onFocus,
  onBlur,
  disableTextAlignSettings,
  initialText,
  intl,
}: Props) => {
  const quillOptions = useMemo(() => {
    const modules = {
      toolbar: {
        container: [
          { header: [1, 2, 3, false] },
          { list: 'bullet' },
          { list: 'ordered' },
          { align: ['left', 'right', 'center', 'justify'] },
          'bold',
          'italic',
          { color: COLORS },
          'link',
          'clean',
        ],
      },
      clipboard: {
        matchVisual: false,
      },
    };

    if (disableTextAlignSettings) {
      modules.toolbar.container = modules.toolbar.container.filter(
        (item) => !(typeof item === 'object' && 'align' in item)
      );
    }

    return {
      formats: FORMATS,
      theme: 'snow',
      modules,
    };
  }, [disableTextAlignSettings]);

  const { quill, quillRef, Quill } = useQuill(quillOptions);
  // For execute this line only once.
  if (Quill && !quill) {
    importQuillModules(Quill);
  }

  useEffect(() => {
    if (quill) {
      const delta = quill.clipboard.convert(initialText); // Set initial value first time
      quill.setContents(delta, 'silent');

      // @ts-ignore
      // eslint-disable-next-line
      quill.root.setAttribute('spellcheck', false);
      quill.getModule('toolbar').addHandler('color', (value: string) => {
        const newValue = value === 'reset-color' ? false : value;
        quill.format('color', newValue);
      });

      // @ts-ignore
      // eslint-disable-next-line
      const tooltipLinkField = quill.container.querySelector(
        '.ql-tooltip [data-link]'
      );
      if (tooltipLinkField) {
        tooltipLinkField.setAttribute(
          'data-link',
          intl.messages['editor.toolbar.link.placeholder']
        );
      }
    }
  }, [quill, initialText, intl.messages]);

  const handleBlur = useCallback(() => {
    if (quill?.root?.innerHTML) {
      onBlur(quill.root.innerHTML);
    }
  }, [onBlur, quill]);

  const toolbarTexts = useMemo(() => {
    const styleProperties = {
      '--text-type-h1': `"${intl.messages['editor.toolbar.textType.heading-1']}"`,
      '--text-type-h2': `"${intl.messages['editor.toolbar.textType.heading-2']}"`,
      '--text-type-h3': `"${intl.messages['editor.toolbar.textType.heading-3']}"`,
      '--text-type-text': `"${intl.messages['editor.toolbar.textType.text']}"`,
    } as CSSProperties;
    return styleProperties;
  }, [intl.messages]);

  return (
    <div
      style={toolbarTexts}
      className='quill sb-react-quill swiper-no-swiping'
    >
      <div ref={quillRef} onFocus={onFocus} onBlur={handleBlur} />
    </div>
  );
};

export default injectIntl(QuillEditor);
