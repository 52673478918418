/** @prettier */
import axios from 'axios';

import { getSitebuilderURL } from '../../utils/url-helper';
import { getCookie } from '../helpers/common';
import { USER_LOGIN_TOKEN } from '../helpers/cookies';
import { isSandboxMode } from '../helpers/sandbox';

import { initBlock } from './index';

export default async ({ data, translations }) => {
  const PRIVATE_BLOCKS_SELECTOR = '.block.block--private';
  if (document.querySelectorAll(PRIVATE_BLOCKS_SELECTOR).length === 0) {
    return;
  }
  const { domain, environment, isPreviewFrameMode, renderMode } = data;

  const postData = isPreviewFrameMode
    ? {
        isPreview: isSandboxMode(renderMode),
        adminDefaultSubs: window.__USER_TYPE__,
      }
    : {};
  if (!isPreviewFrameMode) {
    postData.xsolla_login_token_sb = getCookie(USER_LOGIN_TOKEN);
  }

  const response = await axios.get(
    `${getSitebuilderURL(environment)}/privateblocks/${domain}`
  );
  if (response?.data && typeof response?.data === 'string') {
    document.querySelectorAll('.block--private').forEach((privateBlock) => {
      const { id } = privateBlock;
      const tempDiv = document.createElement('div');
      tempDiv.style.display = 'none';
      tempDiv.insertAdjacentHTML('afterBegin', response.data);
      const upcomingBlockHtml = tempDiv.querySelector(`#${id}`);
      privateBlock.innerHTML = upcomingBlockHtml;

      const [blockName, blockId] = id.split('-');
      initBlock({
        block: document.querySelector(`#${id}`),
        blockName,
        blockId,
        data,
        translations,
      });
    });
  }
};
